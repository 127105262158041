/*
 * @Author: lanxx
 * @Date: 2021-11-11 16:52:59
 * @LastEditTime: 2022-01-09 13:01:44
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\utils\area.js
 */
export function areaCodetoAreaName(value) {
    const name = {
        "330481": "海宁市",
        "330481108": "黄湾镇",
        "330481003": "海昌街道",
        "330481004": "马桥街道",
        "330481002": "海洲街道",
        "330481107": "斜桥镇",
        "330481001": "硖石街道",
        "330481112": "袁花镇",
        "330481101": "许村镇",
        "330481103": "长安镇",
        "330481105": "周王庙镇",
        "330481110": "盐官镇",
        "330481106": "丁桥镇"
    }
    return name[value]
}

export function areaNametoAreaCode(value) {
    const code = {
        "海宁市": "330481",
        "黄湾镇": "330481108",
        "海昌街道": "330481003",
        "马桥街道": "330481004",
        "海洲街道": "330481002",
        "斜桥镇": "330481107",
        "硖石街道": "330481001",
        "袁花镇": "330481112",
        "许村镇": "330481101",
        "长安镇": "330481103",
        "周王庙镇": "330481105",
        "盐官镇": "330481110",
        "丁桥镇": "330481106"
    }
    return code[value]
}