<!--
 * @Author: lanxx
 * @Date: 2021-11-11 09:31:38
 * @LastEditTime: 2022-05-28 09:59:27
 * @LastEditors: lanxx
 * @Description: Do not edit
 * @FilePath: \prosperity-garden-ui\src\views\county.vue
-->
<template>
  <div
    class="container"
    v-loading="loading"
    element-loading-text="加载中"
    element-loading-background="rgba(0, 0, 0, 0.8)"
  >
    <div class="main">
      <top-header></top-header>
      <div class="content">
        <div class="left">
          <div class="top-total">
            <div class="item">
              <div class="icon">
                <img
                  src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/area_nongtian.png"
                />
              </div>
              <div class="body">
                <span class="num">{{ gardenInfo.totalArea | numFilter }}</span>
                <span class="unit">亩</span>
                <div class="name">总面积</div>
              </div>
            </div>
            <div class="item addbody">
              <div class="icon">
                <img
                  src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/home_tianyuan.png"
                />
              </div>
              <div class="body">
                <span class="num">{{ gardenInfo.totalHouse }}</span>
                <span class="unit">户</span>
                <div class="name">实时结对户数</div>
              </div>
              <div class="body">
                <span class="num">{{ gardenInfo.pairedHouseNum }}</span>
                <span class="unit">户</span>
                <div class="name">累计结对户数</div>
              </div>
            </div>
            <div class="item addbody">
              <div class="icon">
                <img
                  src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/people_nongmin.png"
                />
              </div>
              <div class="body">
                <span class="num">{{ gardenInfo.totalPeople }}</span>
                <span class="unit">人</span>
                <div class="name">实时结对人数</div>
              </div>
              <div class="body">
                <span class="num">{{ gardenInfo.pairedPeopleNum }}</span>
                <span class="unit">人</span>
                <div class="name">累计结对人数</div>
              </div>
            </div>
          </div>
          <div class="year-total">
            <div class="header">销售</div>
            <div class="list">
              <div class="round-rotate clearfix">
                <div class="icon">
                  <div class="round"></div>
                  <img
                    src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/chanliang.png"
                  />
                </div>
                <div class="body">
                  <span class="num">{{
                    sales.totalVolume | numFilter(10000)
                  }}</span>
                  <span class="unit">万斤</span>
                  <div class="name">销售量</div>
                </div>
              </div>
              <div class="round-rotate clearfix">
                <div class="icon">
                  <div class="round"></div>
                  <img
                    src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/xiahsoue.png"
                  />
                </div>
                <div class="body">
                  <span class="num">{{
                    sales.totalAmount | numFilter(10000)
                  }}</span>
                  <span class="unit">万元</span>
                  <div class="name">销售总额</div>
                </div>
              </div>
              <div class="round-rotate clearfix">
                <div class="icon">
                  <div class="round"></div>
                  <img
                    src="https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/meimushouyi.png"
                  />
                </div>
                <div class="body">
                  <span class="num">{{ sales.incomePerMu | numFilter }}</span>
                  <span class="unit">元</span>
                  <div class="name">已实现每亩收益</div>
                </div>
              </div>
            </div>
          </div>
          <title2 name="乡镇街道概况"></title2>
          <div class="county-total">
            <table
              cellspacing="0"
              cellpadding="0"
              border="0"
              table-layout="fixed"
            >
              <tr class="num">
                <td class="header">菜园数</td>
                <td
                  v-for="item in gardenCountByArea"
                  :key="item.townAreaCode"
                  width="7.3%"
                >
                  <div class="bar">
                    <div
                      class="line"
                      :style="{ height: item.height + '%' }"
                    ></div>
                    <div class="text">{{ item.totalGarden }}</div>
                  </div>
                </td>
              </tr>
              <tr class="area">
                <td class="header">地区</td>
                <td v-for="item in gardenCountByArea" :key="item.townAreaCode">
                  {{ item.town }}
                </td>
              </tr>
              <tr>
                <td class="header">面积（亩）</td>
                <td v-for="item in gardenCountByArea" :key="item.townAreaCode">
                  {{ item.totalArea | numFilter }}
                </td>
              </tr>
              <tr>
                <td class="header">户数/人数（实时）</td>
                <td v-for="item in gardenCountByArea" :key="item.townAreaCode">
                  {{ item.pairedHouseNum }}/{{ item.pairedPeopleNum }}
                </td>
              </tr>
            </table>
          </div>
          <div class="map" ref="map"></div>
          <ul class="light-level">
            <li @click="changeScatter(0)">
              <img src="@/assets/images/green.png" />
              <span class="num">{{ lightLevel.lightLevel0 }}</span>
              <span class="unit">个</span>
            </li>
            <li @click="changeScatter(1)">
              <img src="@/assets/images/yellow.png" />
              <span class="num">{{ lightLevel.lightLevel1 }}</span>
              <span class="unit">个</span>
            </li>
            <li @click="changeScatter(2)">
              <img src="@/assets/images/red.png" />
              <span class="num">{{ lightLevel.lightLevel2 }}</span>
              <span class="unit">个</span>
            </li>
          </ul>
        </div>
        <div class="right">
          <title2 name="菜园增收排名"></title2>
          <ul class="garden-list">
            <li>
              <span>菜园</span>
              <span>面积(亩)</span>
              <span>结对户数(户)</span>
              <span>户均增收(元)</span>
            </li>
          </ul>
          <ul class="garden-list garden-list-body income">
            <vue-seamless-scroll :data="income" :class-option="defaultOption">
              <li
                v-for="item in income"
                :key="item.orgId"
                @click="goGarden(item.orgId)"
              >
                <span>{{ item.gardenName }}</span>
                <span>{{ item.area }}</span>
                <span>{{ item.pairedHouseNum }}</span>
                <span>{{ item.incomeIncrement }}</span>
              </li>
            </vue-seamless-scroll>
          </ul>
          <title2 name="成效体现"></title2>
          <div class="sign">
            <div class="one"><span></span>2021年</div>
            <div class="two"><span></span>2022年</div>
          </div>
          <ul class="plant-list" v-if="cxtxData">
            <li>
              <div class="name">菜园个数</div>
              <div class="c" v-if="cxtxData.caiyuanCount">
                <div class="con">
                  <div class="num">48个</div>
                  <div class="bar1">
                    <div
                      class="line"
                      :style="{ width: Number((48*100/90).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
                <div class="con">
                  <div class="num">42个</div>
                  <div class="bar2">
                    <div
                      class="line"
                      :style="{ width: Number((42*100/90).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="name">菜园面积</div>
              <div class="c" v-if="cxtxData.areaSum">
                <div class="con">
                  <div class="num">1138.9亩</div>
                  <div class="bar1">
                    <div
                      class="line"
                      :style="{ width: Number(52) + '%' }"
                    ></div>
                  </div>
                </div>
                <div class="con">
                  <div class="num">1044.8亩</div>
                  <div class="bar2">
                    <div
                      class="line"
                      :style="{ width: Number(48) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="name">结对户</div>
              <div class="c" v-if="cxtxData.houseNum">
                <div class="con">
                  <div class="num">633户</div>
                  <div class="bar1">
                    <div
                      class="line"
                      :style="{ width: Number((633*100/1277).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
                <div class="con">
                  <div class="num">644户</div>
                  <div class="bar2">
                    <div
                      class="line"
                      :style="{ width: Number((644*100/1277).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="name">结对人数</div>
              <div class="c" v-if="cxtxData.peopleNum">
                <div class="con">
                  <div class="num">1165人</div>
                  <div class="bar1">
                    <div
                      class="line"
                      :style="{ width: Number((1165*100/2418).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
                <div class="con">
                  <div class="num">1253人</div>
                  <div class="bar2">
                    <div
                      class="line"
                      :style="{ width: Number((1253*100/2418).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="name">销售额</div>
              <div class="c" v-if="cxtxData.incomeAmount">
                <div class="con">
                  <div class="num">4242200元</div>
                  <div class="bar1">
                    <div
                      class="line"
                      :style="{ width: Number((4242200*100/6822232).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
                <div class="con">
                  <div class="num">2580032元</div>
                  <div class="bar2">
                    <div
                      class="line"
                      :style="{ width: Number((2580032*100/6822232).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </li>
            <li>
              <div class="name">亩产效益</div>
              <div class="c" v-if="cxtxData.AvgIncomeAmount">
                <div class="con">
                  <div class="num">5562元</div>
                  <div class="bar1">
                    <div
                      class="line"
                      :style="{ width: Number((5562*100/8031).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
                <div class="con">
                  <div class="num">2469元</div>
                  <div class="bar2">
                    <div
                      class="line"
                      :style="{ width: Number((2469*100/8031).toFixed(0)) + '%' }"
                    ></div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <!-- <title2 name="种植概览"></title2>
          <el-carousel :interval="5000" indicator-position="none" height="">
            <el-carousel-item v-for="(arr, index) in plant" :key="index">
              <ul class="plant-list">
                <li v-for="item in arr" :key="item.plantVariety">
                  <div class="name">{{ item.plantVariety }}</div>
                  <div class="num">{{ item.totalPreOutput | numFilter }}斤</div>
                  <div class="bar">
                    <div
                      class="line"
                      :style="{ width: item.width + '%' }"
                    ></div>
                  </div>
                </li>
              </ul>
            </el-carousel-item>
          </el-carousel> -->
          <!-- <title2 name="菜园公益服务"></title2>
          <ul class="garden-list">
            <li>
              <span>菜园</span>
              <span>次数</span>
              <span>时长(h)</span>
              <span>投入(元)</span>
            </li>
            <li>
              <span>合计</span>
              <span>{{ serviceResult.totalNum }}</span>
              <span>{{ serviceResult.totalHour }}</span>
              <span>{{ serviceResult.totalInput | numFilter }}</span>
            </li>
          </ul>
          <ul class="garden-list garden-list-body">
            <vue-seamless-scroll :data="service" :class-option="defaultOption">
              <li
                v-for="item in service"
                :key="item.orgId"
                @click="goGarden(item.orgId)"
              >
                <span>{{ item.gardenName }}</span>
                <span>{{ item.totalNum }}</span>
                <span>{{ item.totalHour }}</span>
                <span>{{ item.totalInput | numFilter }}</span>
              </li>
            </vue-seamless-scroll>
          </ul> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { areaNametoAreaCode } from "@/utils/area";
import { $getJson } from "@/utils/fetch";
import { splitToChunks } from "@/utils/index";
import local from "@/utils/local";
export default {
  name: "county",
  data() {
    return {
      loading: true,
      cxtxData: {

      },
      map: null,
      active: "",
      areaName: "海宁市",
      gardenInfo: {},
      sales: {},
      gardenCountByArea: [],
      plant: [],
      serviceResult: {},
      service: [],
      gardenLocation: [],
      lightLevel: {
        lightLevel0: 0,
        lightLevel1: 0,
        lightLevel2: 0,
      },
      income: [],
    };
  },
  computed: {
    defaultOption() {
      return {
        step: 0.4, // 数值越大速度滚动越快
        limitMoveNum: 4, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: false, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  methods: {
    getMap(myChart) {
      const option = {
        tooltip: {
          formatter: (params) => {
            let str = "";
            if (params.seriesType === "scatter") {
              str =
                params.data.gardenName +
                "<br />面积：" +
                params.data.area +
                "（亩）<br />实时结对户数：" +
                params.data.pairedHouseNum +
                "（户）<br />实时结对人数：" +
                params.data.pairedPeopleNum +
                "（人）<br />" +
                (params.data.salesVolume ? "产量" : "预产量") +
                "：" +
                this.numFilter(params.data.output, 10000) +
                "（万斤）<br />销售总额：" +
                this.numFilter(params.data.salesVolume, 10000) +
                "（万元）";
            } else {
              return params.name;
            }
            return str;
          },
        },
        geo: {
          map: this.areaName,
          aspectScale: 1,
          zoom: 1.2,
          roam: false,
          itemStyle: {
            normal: {
              borderWidth: 0,
              shadowColor: "rgba(0, 176, 176, 0.5)",
              shadowBlur: 2,
              shadowOffsetX: -5,
              shadowOffsetY: 10,
            },
          },
        },
        series: [],
      };
      myChart.setOption(option);
      myChart.hideLoading();
      myChart.on("click", (params) => {
        if (params.seriesType === "scatter") {
          this.goGarden(params.data.orgId);
        } else {
          this.$router.push({
            name: "town",
            params: {
              area: areaNametoAreaCode(params.name),
            },
          });
        }
      });
    },
    getCountyTownDetail() {
      this.$api.bigdata
        .getCountyTownDetail({
          areaCode: "330481",
        })
        .then((res) => {
          console.log(res);
          this.loading = false;
          let result = res.data;
          if (!result) return;
          this.getMap(this.map);
          const {
            gardenInfo = {},
            sales = {},
            gardenCountByArea = [],
            plant = [],
            service = [],
            gardenLocation = [],
          } = result;
          this.gardenInfo = gardenInfo;
          this.sales = sales;
          this.gardenCountByArea = gardenCountByArea.map((item) => {
            return {
              ...item,
              height: Math.ceil(
                (item.totalGarden / gardenInfo.totalGarden) * 100
              ),
            };
          });
          let totalPreOutput = plant.reduce((pre, item) => {
            return pre + Number(item.totalPreOutput);
          }, 0);
          let newPlant = plant.map((item) => {
            return {
              ...item,
              width: Math.ceil((item.totalPreOutput / totalPreOutput) * 100),
            };
          });
          this.plant = splitToChunks(newPlant, 4);
          let serviceResult = {
            totalNum: 0,
            totalHour: 0,
            totalInput: 0,
          };
          service.forEach((item) => {
            serviceResult.totalNum += Number(item.totalNum);
            serviceResult.totalHour += Number(item.totalHour);
            serviceResult.totalInput += Number(item.totalInput);
          });
          this.serviceResult = serviceResult;
          this.service = service;
          let mapData = [];
          this.gardenLocation = gardenLocation;
          gardenLocation.forEach((item) => {
            if (item.latitude && item.longitude) {
              mapData.push(this.getSymbol(item));
            }
          });
          this.setOption(mapData);
        });
    },
    countByLightLevel() {
      this.$api.bigdata.countByLightLevel().then((res) => {
        res.data.forEach((item) => {
          this.lightLevel["lightLevel" + item.lightLevel] = item.count;
        });
      });
    },
    incomeIncrement() {
      this.$api.bigdata.incomeIncrement().then((res) => {
        this.income = res.data;
      });
    },
    getData() {
      this.$api.bigdata.gardenInfoByTown().then((res) => {
        this.$nextTick(() => {
          this.cxtxData = res.data
        })
      });
    },
    getSymbol(item) {
      let symbol =
        "https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/symbol.png";
      if (item.photo) {
        let photo = JSON.parse(item.photo);
        if (photo.length) {
          symbol = photo[0].fileUrl + "?x-oss-process=image/resize,h_30,w_30";
        }
      }
      return {
        value: [item.longitude, item.latitude],
        symbol: "image://" + symbol,
        symbolSize: [30, 30],
        ...item,
      };
    },
    changeScatter(level) {
      if (level === this.active) {
        this.active = "";
      } else {
        this.active = level;
      }
      let mapData = [];
      this.gardenLocation.forEach((item) => {
        if (this.active === "") {
          if (item.latitude && item.longitude) {
            mapData.push(this.getSymbol(item));
          }
        } else {
          if (item.lightLevel === level && item.latitude && item.longitude) {
            mapData.push(this.getSymbol(item));
          }
        }
      });
      this.setOption(mapData);
    },
    setOption(data) {
      this.map.setOption({
        series: [
          {
            type: "map",
            map: this.areaName,
            aspectScale: 1,
            zoom: 1.2,
            roam: false,
            label: {
              normal: {
                show: true,
                textStyle: {
                  color: "#FFFFFF",
                  fontSize: "12",
                },
              },
              emphasis: {
                textStyle: {
                  color: "#FFFFFF",
                },
              },
            },
            itemStyle: {
              normal: {
                areaColor: "#007FFC",
                borderWidth: 2,
                borderColor: "#32C5FF",
              },
              emphasis: {
                areaColor: "#007FFC",
                shadowColor: "#32C5FF",
              },
            },
          },
          {
            type: "scatter",
            coordinateSystem: "geo",
            data,
          },
        ],
      });
    },
    goGarden(id) {
      this.$router.push({
        name: "garden",
        params: {
          id,
        },
      });
    },
  },
  mounted() {
    const myChart = this.$echarts.init(this.$refs.map);
    this.map = myChart;
    myChart.showLoading({
      text: "加载中...",
      textColor: "#fff",
      maskColor: "transparent",
    });
    console.log(local);
    $getJson("330481").then((res) => {
      console.log(res);
      this.$echarts.registerMap(this.areaName, res.data);
      // this.$echarts.registerMap(this.areaName, local);
      this.getCountyTownDetail();
      this.countByLightLevel();
      this.incomeIncrement();
      this.getData();//成效体现
    });
  },
};
</script>

<style scoped lang="scss">
.main {
  .content {
    display: flex;
    padding: 24px 46px;
    .left {
      position: relative;
      flex: 1;
      margin-right: 35px;
      .top-total {
        display: flex;
        .item {
          display: flex;
          align-items: center;
          width: 256px;
          height: 113px;
          background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/area.png);
          background-size: 100% 100%;
          font-size: 0;
          &.addbody {
            width: 364px;
          }
          & + .item {
            margin-left: 25px;
          }
          &:nth-child(2) {
            background-image: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/home.png);
            .num,
            .unit {
              color: #00d5ee;
            }
          }
          &:nth-child(3) {
            background-image: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/people.png);
            .num,
            .unit {
              color: #f2b800;
            }
          }
          .icon {
            width: 60px;
            margin: 0 0 0 30px;
            img {
              width: 100%;
            }
          }
          .body {
            padding: 0 20px;
            & + .body {
              border-left: 1px solid #eef8ff;
            }
          }
          .num {
            font-size: 32px;
            font-weight: 600;
            font-style: italic;
            color: #00d387;
          }
          .unit {
            font-size: 16px;
            margin-left: 3px;
            color: #00d387;
          }
          .name {
            font-size: 16px;
            line-height: 30px;
          }
        }
      }
      .year-total {
        margin: 30px 0;
        width: 239px;
        height: 427px;
        background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/year.png);
        background-size: 100% 100%;
        .header {
          height: 70px;
          line-height: 70px;
          text-align: center;
          font-size: 24px;
        }
        .list {
          .round-rotate {
            margin: 10px 0;
          }
        }
      }
      .county-total {
        font-size: 18px;
        margin-top: 30px;
        border-top: 1px solid rgba(0, 149, 255, 0.5);
        border-left: 1px solid rgba(0, 149, 255, 0.5);
        table {
          width: 100%;
          tr {
            &.area {
              td {
                background: rgba(0, 63, 152, 1);
                text-shadow: 0 0 2px #007ffc;
              }
            }
            &.num {
              td {
                &:nth-child(odd) {
                  .line {
                    background-image: linear-gradient(
                      0deg,
                      rgba(247, 181, 0, 0) 0%,
                      rgba(247, 181, 0, 0.75) 100%
                    );
                    border-color: rgba(#f7b500, 0.7);
                  }
                }
              }
            }
            td {
              text-align: center;
              vertical-align: bottom;
              padding: 5px 0;
              line-height: 42px;
              border-right: 1px solid rgba(0, 149, 255, 0.5);
              border-bottom: 1px solid rgba(0, 149, 255, 0.5);
              background: rgba(0, 63, 152, 0.4);
              &.header {
                color: #00aaff;
              }
            }
          }
        }
        .bar {
          position: relative;
          height: 115px;
          width: 70%;
          margin: 0 auto;
          .text {
            position: absolute;
            width: 100%;
            bottom: 0;
          }
          .line {
            position: absolute;
            width: 100%;
            bottom: 0;
            height: 50%;
            background-image: linear-gradient(
              0deg,
              rgba(0, 199, 255, 0) 0%,
              rgba(0, 149, 255, 0.75) 100%
            );
            border: 2px solid rgba(#00afff, 0.7);
            border-bottom: none;
            transition: height 0.3s;
          }
        }
      }
      .map {
        position: absolute;
        top: 80px;
        left: 250px;
        right: 0;
        bottom: 250px;
      }
      .light-level {
        position: absolute;
        left: 50%;
        transform: translateX(-25%);
        top: 540px;
        font-size: 0;
        text-align: center;
        li {
          margin: 0 8px;
          display: inline-block;
          cursor: pointer;
          width: 160px;
          height: 56px;
          line-height: 56px;
          background-size: 100% 100%;
          &:nth-child(1) {
            background-image: url(../assets/images/green_bg.png);
          }
          &:nth-child(2) {
            background-image: url(../assets/images/yellow_bg.png);
          }
          &:nth-child(3) {
            background-image: url(../assets/images/red_bg.png);
          }
          img {
            display: inline-block;
            width: 40px;
            vertical-align: top;
            margin-top: 7px;
          }
          span {
            display: inline-block;
            vertical-align: top;
            &.num {
              font-size: 18px;
              font-style: italic;
              margin: 0 2px 0 6px;
            }
            &.unit {
              font-size: 14px;
            }
          }
        }
      }
    }
    .right {
      width: 440px;
      ::v-deep .el-carousel__container {
        height: 234px;
        margin: 21px 0;
      }
      .plant-list {
        overflow: hidden;
        li {
          display: flex;
          padding: 0 16px;
          height: 84px;
          line-height: 54px;
          font-size: 18px;
          background: url(https://zhenongfu.oss-cn-hangzhou.aliyuncs.com/prosperity-garden/assets/icons/bigdata1/plant.png);
          background-size: 100% 100%;
          & + li {
            margin-top: 10px;
          }
          .c {
            margin-top: 15px;
            margin-left: auto;
          }
          .con {
            display: flex;
            height: 20px;
            line-height: 20px;
            margin-top: 8px;
          }
          .name {
            width: 130px;
            padding-top: 20px;
            font-size: 18px;
            font-weight: 500;
            color: #fff;
          }
          .num {
            width: 130px;
            color: #fff;
            font-size: 16px;
            font-weight: 500;
          }
          .bar1 {
            width: 182px;
            .line {
              height: 20px;
              width: 50%;
              background: linear-gradient(270deg, #2B68FF 0%, #1DAEFF 55%, rgba(77,166,231,0.67) 99%);
            }
          }
          .bar2 {
            width: 182px;
            .line {
              height: 20px;
              width: 50%;
              background: linear-gradient(270deg, #F78F1A 0%, #F9B91F 60%, rgba(249,185,31,0.64) 99%);
            }
          }
        }
      }
      .garden-list {
        margin: 10px 0 0;
        span {
          font-size: 16px;
        }
        &.garden-list-body {
          margin: 0;
          height: 189px;
          overflow: hidden;
          li {
            cursor: pointer;
          }
        }
        &.income {
          margin: 0 0 22px 0;
        }
        li {
          font-size: 0;
          &:nth-child(even) {
            background: rgba(0, 63, 152, 0.3);
          }
          span {
            display: inline-block;
            width: 35%;
            font-size: 16px;
            text-align: center;
            line-height: 46px;
            &:nth-child(2) {
              color: #fec700;
              width: 15%;
            }
            &:nth-child(3) {
              color: #65d05f;
              width: 25%;
            }
            &:nth-child(4) {
              color: #0095ff;
              width: 25%;
            }
          }
          border-bottom: 1px solid rgba(0, 149, 255, 0.5);
        }
      }
    }
  }
}
.sign {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0;
  text-align: right;
  .one {
    margin-left: auto;
    span {
      width: 10px;
      height: 10px;
      display: inline-block;
      background: #FEC700;
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }
  .two {
    padding-left: 10px;
    span {
      width: 10px;
      height: 10px;
      display: inline-block;
      background: #00AAFF;
      margin-right: 5px;
      position: relative;
      top: -2px;
    }
  }
}
</style>
